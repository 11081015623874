<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <div class="box">
        <div class="columns is-mobile is-multiline">
          <div class="column is-10-desktop is-12-mobile">
            <div class="field">
              <label for="" class="label">Job Title <span>*</span></label>
              <p class="control has-icons-right">
                <ValidationProvider name="Job Title" rules="required" v-slot="{ errors }">
                  <input :disabled="disabled" class="input" v-model="form.title" type="text" placeholder="Job Title"
                    required />
                  <span class="icon is-small is-right">
                    <i class="fas fa-check-circle"></i>
                  </span>
                  <span style="color: red; font-size: 12px">{{
                    errors[0]
                  }}</span>
                </ValidationProvider>
              </p>
            </div>
          </div>
          <div class="column is-2-desktop is-12-mobile">
            <div class="field">
              <label for="" class="label">Staff QTY <span>*</span></label>
              <div class="control">
                <input v-model="form.staffQTY" type="text" class="input" placeholder="0" />
                <br />
              </div>
            </div>
          </div>
          <div class="column is-6-desktop is-12-mobile">
            <div class="field">
              <label for="" class="label">Job Function <span>*</span></label>
              <div class="control">
                <!-- <ValidationProvider name="Select Job Function" rules="required" v-slot="{ errors }">
                  <selection v-model="form.job" :items="jobs" name="Job Function" />
                  <span style="color: red; font-size: 12px">{{
                    errors[0]
                  }}</span>
                </ValidationProvider> -->
                <div v-if="!jobFunctionLoading">
                  <selctJobFunction v-model="form.job" :updateText="jobFunctionInfo"  />
                </div>

                 <div v-else-if="!$route.query.id">
                  <selctJobFunction v-model="form.job"  />
                 </div>
               
                <!-- {{form.job}}  -->
              </div>
            </div>
          </div>
          <div class="column is-6-desktop is-12-mobile">
            <div class="field">
              <label for="" class="label">Working Experience <span>*</span></label>
              <div class="control">
                <div class="select is-fullwidth">
                  <ValidationProvider name="Experince" rules="required|excluded" v-slot="{ errors }">
                    <select v-model="form.experience" class="input" :class="{ error: errors[0] }">
                      <option v-for="(exp, idx) in experiences" :key="idx" :value="exp._id">
                        {{ exp.name }}
                      </option>
                    </select>
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-6-desktop is-12-mobile">
            <div class="field">
              <label for="" class="label">Required Language <span>*</span></label>
              <div class="control">
                <ValidationProvider name="Select Language" rules="required" v-slot="{ errors }">
                  <selection v-model="form.language" :items="languages" name="Language" />
                  <span style="color: red; font-size: 12px">{{
                    errors[0]
                  }}</span>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="column is-6-desktop is-12-mobile">
            <div class="field">
              <label for="" class="label">Working Location <span>*</span></label>
              <div class="control">
                <ValidationProvider name="Select Working Location" rules="required" v-slot="{ errors }">
                  <selection v-model="form.location" :items="locations" name="Location" />
                  <span style="color: red; font-size: 12px">{{
                    errors[0]
                  }}</span>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="column is-6-desktop is-12-mobile">
            <div class="field">
              <label for="" class="label">Education Level <span>*</span></label>
              <div class="control">
                <div class="select is-fullwidth">
                  <ValidationProvider name="Level" rules="required|excluded" v-slot="{ errors }">
                    <select v-model="form.level" class="input" :class="{ error: errors[0] }">
                      <option v-for="(lv, idx) in levels" :key="idx" :value="lv._id">
                        {{ lv.name }}
                      </option>
                    </select>
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-6-desktop is-12-mobile">
            <div class="field">
              <label for="" class="label">Salary <span>*</span> / Show Salary
                <label class="switch">
                  <input type="checkbox" v-model="form.salaryid" value="true" />
                  <span class="slider round"></span> </label></label>

              <div class="select is-fullwidth">
                <ValidationProvider name="Salary" rules="required|excluded" v-slot="{ errors }">
                  <select v-model="form.salary" class="input" :class="{ error: errors[0] }">
                    <option v-for="(sl, idx) in salaries" :key="idx" :value="sl._id">
                      {{ sl.name }}
                    </option>
                  </select>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="column is-6-desktop is-12-mobile">
            <div class="field">
              <label for="" class="label">Job Level <span>*</span></label>
              <div class="control">
                <div class="select is-fullwidth">
                  <ValidationProvider name="job Level" rules="required|excluded" v-slot="{ errors }">
                    <select v-model="form.jobLevelId" class="input" :class="{ error: errors[0] }">
                      <option v-for="(lv, idx) in jobLevelInfo" :key="idx" :value="lv._id">
                        {{ lv.name }}
                      </option>
                    </select>
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-12-desktop is-12-mobile">
            <div class="field">
              <label for="" class="label">Job Description <span>*</span></label>
              <div class="control">
                <ValidationProvider name="Description" rules="required" v-slot="{ errors }">
                  <div v-if="!isLoading">
                    <FroalaEditor v-model="form.description" />
                  </div>
                  <FroalaEditor v-if="isLoading" v-model="form.description" />
                  <span style="color: red; font-size: 12px">{{
                    errors[0]
                  }}</span>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="column is-4-desktop is-12-mobile">
            <div class="field">
              <label for="" class="label">Post Period <span>*</span></label>
              <div class="control">
                <ValidationProvider name="Period" rules="required|integer" v-slot="{ errors }">
                  <input type="number" step="1" min="1" class="input" :class="{ error: errors[0] }" v-model="form.period"
                    @change="periodKeychange" :disabled="disabled"
                    placeholder="Please fill number of week and press Enter" @input="filterInput" />
                  <!-- ======= @change="periodKeychange" :disabled="disabled" placeholder="0" @input="filterInput" />
                  >>>>>>> e69b8ec (update design) -->
                  <span style="color: red; font-size: 12px">{{
                    errors[0]
                  }}</span>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="column is-4-desktop is-12-mobile">
            <div class="field">
              <label for="" class="label">Start Post Date <span>*</span></label>
              <div class="control">
                <date-picker type="date" :disabled-date="notBeforeToday" v-model="form.postdate" class="mt-1"
                  valueType="date" :clearable="false" @change="periodKeychange" :disabled="disabled" />
              </div>
            </div>
          </div>
          <div class="column is-4-desktop is-12-mobile">
            <div class="field">
              <label for="" class="label">Expired Date <span>*</span></label>
              <div class="control">
                <ValidationProvider name="Expired Date" rules="required" v-slot="{ errors }">
                  <date-picker class="mt-1" type="date" v-model="form.expiredate" :disabled="true" valueType="date"
                    :clearable="false" :class="{ error: errors[0] }" />
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Box -->
      <!-- :disabled="!whateverActivatesThisLink" :event="whateverActivatesThisLink ? 'click' : ''" -->
      <div class="btn-group">
        <!-- <button @click="postDraft()" class="button info">Save as draft</button> -->
        <button v-if="$route.query.id && $route.query.type === 'draft'" class="button primary"
          @click="handleSubmit(updateDraft)">
          Save and Next
        </button>
        <button v-if="$route.query.type !== 'expired' &&
          $route.query.type !== 'draft' &&
          $route.query.post !== 'postjob'
          " class="button primary" @click="handleSubmit(update)">
          Update
        </button>
        <button v-if="!$route.query.id && $route.query.type !== 'draft'" class="button primary"
          @click="handleSubmit(postDraft)">
          Save and Next
        </button>
        <button v-if="$route.query.id && $route.query.type === 'expired'" class="button primary"
          @click="handleSubmit(repost)">
          Save Draft
        </button>
        <!-- <button @click="addEmit()">fdgdgf</button> -->
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import apolloClient_Company from "@/services/apollo-client-company";
import { GET_COMPANY_POFILE } from "../../graphql/Company";
import selctJobFunction from "../SelectJobFunction.vue"
import Multiselect from "../Multiselect";
import Selection from "../selection.vue";
import {
  POST_JOB,
  SAVE_DRAFT,
  GET_JOBID,
  UPDATE_DRAFT,
  UPDATE_JOB,
} from "@/graphql/Job";
import { GET_REUSE } from "@/graphql/Reuse";
import FroalaEditor from "../FroalaEditor";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";
export default {
  props: ["iget"],
  components: {
    Multiselect,
    Selection,
    FroalaEditor: FroalaEditor,
    DatePicker,
    selctJobFunction
  },
  data: () => ({
    disabled: true,
    //  iget: null,
    piontget: "",
    whateverActivatesThisLink: true,
    country: "",
    d1: "",
    d2: "",
    days: "",
    difference_ms: "",
    getDays: "",
    getweek: "",
    isLoading: true,
    clickJob: false,
    salaries: [],
    selectedJob: [],
    experiences: [],
    jobLevelInfo: [],
    languages: [],
    levels: [],
    jobs: [],
    locations: [],
    getjobId: [],
    form: {
      getPoints: "",
      period: null,
      getTag: [""],
      file: "",
      companyId: "",
      title: "",
      job: [""],
      location: [""],
      experience: "",
      jobLevelId: "",
      level: "",
      language: [""],
      salary: "",
      salaryid: true,
      description: "",
      tagIds: [],
      zone: [],
      coverletter: false,
      apply: false,
      uselink: false,
      link: "",
      applied: [],
      nortification: [],
      notifyExpire: [],
      ispublished: false,
      postdate: null,
      closedate: "",
      expiredate: "",
      details: "",
      staffQTY: "",
      getPointUpgrade: "",
    },
    jobFunctionInfo: [],
    jobFunctionLoading:true
  }),

  methods: {
    notBeforeToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },
    filterInput(e) {
      e.target.value = e.target.value.replace(/[^0-9]+/g, "");
    },
    addEmit() {
      this.$emit("putdata", this.form);
      if (this.$route.query.id) {
        this.$router.push({
          name: "email",
          query: { id: this.$route.query.id, type: this.$route.query.type },
        });
      } else {
        this.$router.push({ name: "email" });
      }
    },

    periodKeychange() {
      const isCheckDate = moment(this.form.postdate).format("DD/MM/YYYY");
      const isDate = moment(new Date()).format("DD/MM/YYYY");
      if (isCheckDate > isDate) {
        this.form.expiredate = moment(this.form.postdate).add(
          7 * parseInt(this.form.period, 10),
          "days"
        )._d;
        this.form.closedate = moment(this.form.postdate).add(
          7 * parseInt(this.form.period, 10),
          "days"
        )._d;
      } else {
        this.form.expiredate = moment(this.form.postdate).add(
          7 * parseInt(this.form.period, 10),
          "days"
        )._d;
        this.form.closedate = moment(this.form.postdate).add(
          7 * parseInt(this.form.period, 10),
          "days"
        )._d;
      }
      this.form.getPoints = this.form.period * 20;
      this.form.getPointUpgrade = "";
      if (this.iget.getPoints > this.piontget) {
        this.points = this.iget.getPoints - this.piontget;
        //  console.log(this.points)
        if (this.$route.query.id) {
          this.whateverActivatesThisLink = false;
        }
        const isConfirmed = this.$dialog.alert(
          "Point not enough " + this.points
        );
        if (!isConfirmed) return;
      } else {
        this.whateverActivatesThisLink = true;
      }
    },
    async fetchLocation() {
      const res = await apolloClient_Company.query({
        query: GET_REUSE,
        variables: {
          name: this.locations,
          type: "Province",
          page: 1,
          perPage: 1000,
        },
      });

      this.locations = res.data.getReuse.reuseType;
      var name = this.locations.map((i) => i._id);
      var id = "63033704a6e3da0ca0606ca9";
      if (this.country == "Laos") {
        if (name.includes(id)) {
          const foundIdx = this.locations.findIndex((i) => i === name);
          name = this.locations.splice(foundIdx, 1);
        }
      }
    },
    async fetchLevel() {
      const res = await apolloClient_Company.query({
        query: GET_REUSE,
        variables: {
          name: this.levels,
          type: "Degree",
          page: 1,
          perPage: 100,
        },
      });
      this.levels = res.data.getReuse.reuseType;
      this.form.level = this.levels[0]._id;
    },
    async fetchSalary() {
      const res = await apolloClient_Company.query({
        query: GET_REUSE,
        variables: {
          name: this.salaries,
          type: "SalaryRange",
          page: 1,
          perPage: 100,
        },
      });
      this.salaries = res.data.getReuse.reuseType;
      this.form.salary = this.salaries[0]._id;
    },
    async fetchlanguage() {
      const res = await apolloClient_Company.query({
        query: GET_REUSE,
        variables: {
          name: this.languages,
          type: "Language",
          page: 1,
          perPage: 100,
        },
      });
      this.languages = res.data.getReuse.reuseType;
    },
    async fetchJobs() {
      const res = await apolloClient_Company.query({
        query: GET_REUSE,
        variables: {
          name: this.jobs,
          type: "JobFunction",
          page: 1,
          perPage: 1000,
        },
      });

      this.jobs = res.data.getReuse.reuseType;
      //this.form.job = this.jobs[0]._id
    },
    async fetchExperience() {
      const res = await apolloClient_Company.query({
        query: GET_REUSE,
        variables: {
          name: this.experiences,
          type: "JobExperience",
          page: 1,
          perPage: 1000,
        },
      });

      this.experiences = res.data.getReuse.reuseType;
      this.form.experience = this.experiences[0]._id;
    },
    async fetchJobLevel() {
      const res = await apolloClient_Company.query({
        query: GET_REUSE,
        variables: {
          name: this.experiences,
          type: "JobLevel",
          page: 1,
          perPage: 1000,
        },
      });

      this.jobLevelInfo = res.data.getReuse.reuseType;
      // this.form.jobLevelId = this.jobLevelInfo[0]._id;
    },
    async postJob() {
      await apolloClient_Company.query({
        query: POST_JOB,
        variables: {
          title: this.form.title,
          description: this.form.description,
          jobFunctionId: this.form.job.map((i) => i._id),
          workingLocationId: this.form.location,
          jobExperienceId: this.form.experience,
          jobLanguageId: this.form.language,
          jobEducationLevelId: this.form.level,
          salayRangeId: this.form.salary,
          isShowSalary: this.form.salaryid,
          tagIds: this.form.getTag,
          jobZoneIds: [],
          jobLevelId: this.form.jobLevelId,
          openingDate: this.form.postdate,
          closingDate: this.form.closedate,
          expiredDate: this.form.expiredate,
          isApplyUrl: this.form.uselink,
          isCoverLetter: false,
          isNotApply: this.form.apply,
          isHideApplyButton: this.form.apply,
          appliedEmails: this.form.applied,
          nortificationEmails: this.form.nortification,
          notifyExpire: this.form.notifyExpire,
          isPublished: true,
          //image: this.form.file,
          isURL: this.form.link,
          details: this.form.details,
          staffQTY: this.form.staffQTY,
        },
      });
    },
    async repost() {
      const isConfirmed = await this.$dialog.confirm();
      if (!isConfirmed) return;
      const res = await apolloClient_Company.query({
        query: SAVE_DRAFT,
        variables: {
          title: this.form.title,
          description: this.form.description,
          jobFunctionId: this.form.job.map((i) => i._id),
          workingLocationId: this.form.location,
          jobExperienceId: this.form.experience,
          jobLanguageId: this.form.language,
          jobEducationLevelId: this.form.level,
          salayRangeId: this.form.salary,
          isShowSalary: this.form.salaryid,
          tagIds: this.form.tagIds,
          jobZoneIds: [], //this.form.zone,
          jobLevelId: this.form.jobLevelId,
          openingDate: this.form.postdate,
          closingDate: this.form.closedate,
          expiredDate: this.form.expiredate,
          isApplyUrl: false, // this.form.uselink,
          isCoverLetter: false, //this.form.coverletter,
          isNotApply: this.form.apply,
          isHideApplyButton: this.form.apply,
          appliedEmails: this.form.applied,
          nortificationEmails: this.form.nortification,
          notifyExpire: this.form.notifyExpire,
          isPublished: true,
          image: "", //this.form.file,
          isURL: this.form.link,
          details: "", //this.form.details,
          staffQTY: this.form.staffQTY,
        },
      });
      this.getID = res.data.saveDraft;
      this.$emit("putdata", this.form);
      if (this.$route.query.id) {
        this.$router.push({
          name: "email",
          query: { ...this.$route.query, id: this.getID._id, type: "draft" },
        });
      }
    },

    async postDraft() {
      if (this.piontget >= this.iget.getPoints) {
        const isConfirmed = await this.$dialog.confirm();
        if (!isConfirmed) return;
        const res = await apolloClient_Company.query({
          query: SAVE_DRAFT,
          variables: {
            title: this.form.title,
            description: this.form.description,
            jobFunctionId: this.form.job.map((i) => i._id),
            workingLocationId: this.form.location,
            jobExperienceId: this.form.experience,
            jobLanguageId: this.form.language,
            jobEducationLevelId: this.form.level,
            salayRangeId: this.form.salary,
            isShowSalary: this.form.salaryid,
            tagIds: [], //this.form.tagIds,
            jobZoneIds: [], //this.form.zone,
            jobLevelId: this.form.jobLevelId,
            openingDate: this.form.postdate,
            closingDate: this.form.closedate,
            expiredDate: this.form.expiredate,
            isApplyUrl: false, // this.form.uselink,
            isCoverLetter: false, //this.form.coverletter,
            isNotApply: false, //this.form.apply,
            isHideApplyButton: false, //this.form.apply,
            appliedEmails: this.form.applied,
            nortificationEmails: this.form.nortification,
            notifyExpire: this.form.notifyExpire,
            isPublished: true,
            image: "", //this.form.file,
            isURL: "", //this.form.link,
            details: "", //this.form.details,
            staffQTY: this.form.staffQTY,
          },
        });
        this.getID = res.data.saveDraft;
        this.$emit("putdata", this.form);
        if (this.$route.query.id) {
          this.$router.push({
            name: "email",
            query: {
              ...this.$route.query,
              id: this.$route.query.id,
              type: this.$route.query.type,
            },
          });
        } else {
          this.$router.push({
            name: "email",
            query: { ...this.$route.query, id: this.getID._id, type: "draft" },
          });
        }
      } else {
        await apolloClient_Company.query({
          query: SAVE_DRAFT,
          variables: {
            title: this.form.title,
            description: this.form.description,
            jobFunctionId: this.form.job,
            workingLocationId: this.form.location,
            jobExperienceId: this.form.experience,
            jobLanguageId: this.form.language,
            jobEducationLevelId: this.form.level,
            salayRangeId: this.form.salary,
            isShowSalary: this.form.salaryid,
            tagIds: [], //this.form.tagIds,
            jobZoneIds: [], //this.form.zone,
            openingDate: this.form.postdate,
            closingDate: this.form.closedate,
            expiredDate: this.form.expiredate,
            isApplyUrl: false, // this.form.uselink,
            isCoverLetter: false, //this.form.coverletter,
            isNotApply: false, //this.form.apply,
            isHideApplyButton: false, //this.form.apply,
            appliedEmails: this.form.applied,
            nortificationEmails: this.form.nortification,
            notifyExpire: this.form.notifyExpire,
            isPublished: true,
            image: "", //this.form.file,
            isURL: "", //this.form.link,
            details: "", //this.form.details,
            staffQTY: this.form.staffQTY,
          },
        });
        this.$router.push({ name: "myjob" });
      }
    },
    async update() {
      try {
        if (this.$route.query.id) {
          await apolloClient_Company.mutate({
            mutation: UPDATE_JOB,
            variables: {
              jobId: this.$route.query.id,
              employerId: this.form.companyId,
              title: this.form.title,
              description: this.form.description,
              jobFunctionId: this.form.job.map((i) => i._id),
              workingLocationId: this.form.location,
              jobExperienceId: this.form.experience,
              jobLanguageId: this.form.language,
              jobEducationLevelId: this.form.level,
              salayRangeId: this.form.salary,
              isShowSalary: this.form.salaryid,
              tagIds: this.form.tagIds,
              jobZoneIds: [],
              jobLevelId: this.form.jobLevelId,
              openingDate: this.form.postdate,
              closingDate: this.form.closedate,
              expiredDate: this.form.expiredate,
              isApplyUrl: false, // this.form.uselink,
              isCoverLetter: false, //this.form.coverletter,
              isNotApply: this.form.apply,
              isHideApplyButton: this.form.apply,
              appliedEmails: this.form.applied,
              nortificationEmails: this.form.nortification,
              notifyExpire: this.form.notifyExpire,
              isPublished: this.form.ispublished,
              image: "", //this.form.file,
              isURL: this.form.link,
              details: "", //this.form.details,
              staffQTY: this.form.staffQTY,
            },
          });
          //  this.$router.back();
          this.$emit("putdata", this.form);
          if (this.$route.query.id) {
            this.$router.push({
              name: "email",
              query: {
                ...this.$route.query,
                id: this.$route.query.id,
                type: this.$route.query.type,
              },
            });
          }
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    async updateDraft() {
      const res = await apolloClient_Company.query({
        query: UPDATE_DRAFT,
        variables: {
          _id: this.$route.query.id,
          title: this.form.title,
          description: this.form.description,
          jobFunctionId: this.form.job.map((i) => i._id),
          workingLocationId: this.form.location,
          jobExperienceId: this.form.experience,
          jobLanguageId: this.form.language,
          jobEducationLevelId: this.form.level,
          salayRangeId: this.form.salary,
          isShowSalary: this.form.salaryid,
          tagIds: this.form.tagIds,
          jobZoneIds: this.form.zone,
          jobLevelId: this.form.jobLevelId,
          openingDate: this.form.postdate,
          closingDate: this.form.closedate,
          expiredDate: this.form.expiredate,
          isApplyUrl: false, // this.form.uselink,
          isCoverLetter: false, //this.form.coverletter,
          isNotApply: this.form.apply,
          isHideApplyButton: this.form.apply,
          appliedEmails: this.form.applied,
          nortificationEmails: this.form.nortification,
          notifyExpire: this.form.notifyExpire,
          isPublished: true,
          image: "", //this.form.file,
          isURL: this.form.link,
          details: "", //this.form.details,
          staffQTY: this.form.staffQTY,
        },
      });
      this.$emit("putdata", this.form);
      if (this.$route.query.id) {
        this.$router.push({
          name: "email",
          query: { id: this.$route.query.id, type: this.$route.query.type },
        });
      } else {
        this.$router.push({ name: "email" });
      }
    },

    async GetJobId() {
      this.jobFunctionLoading=true
      const res = await apolloClient_Company.query({
        query: GET_JOBID,
        variables: {
          type: this.$route.query.type,
          jobId: this.$route.query.id,
        },
      });
      this.isLoading = false;
      this.getjobId = res.data.editJob;

      setTimeout(()=>{
        this.jobFunctionInfo = this.getjobId.jobFunctionId;

        setTimeout(()=>{
            this.jobFunctionLoading=false
        },500)
      },500)
      
      this.form.tagIds =
        this.getjobId.tagIds !== null
          ? this.getjobId.tagIds.map((i) => i._id)
          : null;

      this.form.title = this.getjobId.title;
      this.form.apply = this.getjobId.isNotApply;
      this.form.link = this.getjobId.isURL;
      this.form.description = this.getjobId.description;
      this.form.applied = this.getjobId.appliedEmails;
      this.form.nortification = this.getjobId.nortificationEmails;
      this.form.notifyExpire = this.getjobId.notifyExpire;
      this.form.staffQTY = this.getjobId.staffQTY;
      this.form.salaryid = this.getjobId.isShowSalary;
      this.form.job = this.getjobId.jobFunctionId.map((i) => i._id);
      
      this.form.language = this.getjobId.jobLanguageId.map((i) => i._id);
      this.form.location = this.getjobId.workingLocationId.map((i) => i._id);
      this.form.experience = this.getjobId.jobExperienceId._id;
      this.form.jobLevelId = this.getjobId.jobLevelId ? this.getjobId.jobLevelId._id : "";
      this.form.level = this.getjobId.jobEducationLevelId._id;
      this.form.salary = this.getjobId.salayRangeId._id;

        
      //}
      if (this.$route.query.type === "expired") {
        this.form.postdate = new Date();
        this.form.closedate = "";
        this.form.expiredate = "";
      } else {
        this.form.postdate = new Date(this.getjobId.openingDate);
        this.form.closedate = new Date(this.getjobId.closingDate);
        this.form.expiredate = new Date(this.getjobId.expiredDate);
      }

      this.form.companyId = this.getjobId.employerId._id;
      //this.form.zone = this.getjobId.jobZoneIds.map((value) => value._id),
      this.d1 = moment(this.form.postdate);
      this.d2 = moment(this.form.expiredate);
      this.days = 24 * 60 * 60 * 1000 * 1;
      this.difference_ms = Math.abs(this.d1 - this.d2);
      this.getDays = Math.floor(this.difference_ms / this.days) + 1;
      this.form.period = Math.floor(this.getDays / 7);

      if (this.form.period) {
        this.form.getPoints = this.form.period * 20;
      }
      if (this.form.getPoints > this.piontget) {
        this.points = this.form.getPoints - this.piontget;

        this.whateverActivatesThisLink = false;
        if (!this.$route.query.id) {
          const isConfirmed = this.$dialog.alert(
            "Point not enough" + this.points
          );
          if (!isConfirmed) return;
        }
      } else {
        this.whateverActivatesThisLink = true;
      }
    },
  },
  watch: {
    "$route.query.id": {
      handler(val) {
        if (val) {
          this.fetchSalary(),
            this.fetchJobs(),
            this.fetchExperience(),
            this.fetchlanguage(),
            this.fetchLevel(),
            this.fetchJobLevel(),
            this.fetchLocation();
        }
      },
      immediate: true,
      deep: true,
    },
    $route: {
      handler(val) {
        if (!this.$route.query.post && this.iget) {
          this.fetchSalary(),
            this.fetchJobs(),
            this.fetchExperience(),
            this.fetchlanguage(),
            this.fetchLevel(),
            this.fetchJobLevel(),
            this.fetchLocation();

          this.form = this.iget;
          this.form.experience = this.iget.experience;
        } else {
        }
      },
      immediate: true,
    },
  },
  async created() {
    //this.form = this.iget
    const res = await apolloClient_Company.query({
      query: GET_COMPANY_POFILE,
    });
    this.company = res.data.profile;
    this.piontget = this.company.points.point;
    this.country = this.company.country;
    await this.fetchSalary(), await this.fetchJobs();
    await this.fetchExperience(),
      await this.fetchlanguage(),
      await this.fetchLevel(),
      await this.fetchLocation();
    await this.fetchJobLevel()
    if (this.$route.query.id) {
      this.GetJobId();
    }
    if (this.$route.query.post) {
      this.disabled = false;
    } else if (this.$route.query.type === "draft") {
      this.disabled = false;
    } else if (this.$route.query.type === "expired") {
      this.disabled = false;
    }
    //else{
    //   this.disabled = false
    // }
    if (this.$route.query.type === "offline") {
      this.form.ispublished = false;
    } else {
      this.form.ispublished = true;
    }

    if (!this.iget) {
      this.$emit("putdata", this.form);
    }
    setTimeout(() => {
      const d = new Date();
      this.form.postdate = new Date(d.setHours(6, 0, 0, 0));
    });
  },
};
</script>

<style lang="scss" scoped>
.mt-1 {
  width: 100%;
}

.error {
  border: 2px solid var(--alert-color);
  color: var(--alert-color);
}

.form {
  .icon {
    transition: all ease-in-out 0.2s;
  }

  label {
    font-size: 16px;
    font-weight: normal;
    margin: 0;
  }
}

.label span {
  color: red;

  &.optional {
    color: var(--dark-grey-color);
    font-weight: normal;
    font-size: 14px;
  }
}

hr {
  background-color: var(--dark-grey-color);
  height: 1px;
}

.btn-group {
  display: flex;
  justify-content: space-between;

  @include mobile {
    display: block;
    width: 100%;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 28px;
  height: 14px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: red;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: green;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(10px);
  -ms-transform: translateX(10px);
  transform: translateX(14px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
