export const mapper =(info)=>{
    return {
        _id: info._id,
        name: info.name,
        isCheck: info.isCheck
    }
}
//keep code
// setStatus(obj) {
//       this.info.map((i, index) => {
//         if (i._id === obj._id && !i.isCheck) {
//           this.info[index].isCheck = true;
//           this.info[index].status = true;
//           this.selected.push(mapper(i));
//           i.items.map((it, indx) => {
//             i.items[indx].isCheck = true;
//             this.selected.push(it);
//           });
//         } else if (i._id === obj._id && i.isCheck) {
//           this.info[index].isCheck = false;
//           this.info[index].status = false;
//           this.selected.forEach((item, index) => {
//             if (item._id === i._id) this.selected.splice(index, 1);
//           });
//           i.items.map((it, indx) => {
//             i.items[indx].isCheck = false;
//             this.selected.forEach((item, ix) => {
//               if (item._id === it._id) this.selected.splice(ix, 1);
//             });
//           });
//         } else {
//           i.items.map((item, indx) => {
//             if (item._id === obj._id && !item.isCheck) {
//               this.info[index].isCheck = true;
//               i.items[indx].isCheck = true;
//               const isfind = this.selected.find((f) => f._id === i._id);
//               if (isfind) {
//                 this.selected.push(item);
//               } else {
//                 this.selected.push(mapper(i), item);
//               }
//             } else if (item._id === obj._id && item.isCheck) {
//               i.items[indx].isCheck = false;
//               this.selected.forEach((items, idx) => {
//                 if (items._id === item._id) this.selected.splice(idx, 1);
//               });
//               // const f = i.items.filter((f) => f.isCheck)
//               // if (!f.length) {
//               //   this.info[index].isCheck = false
//               //   this.selected.forEach((o, idx) => {
//               //     if (o._id === i._id) this.selected.splice(idx, 1)
//               //   })
//               // }
//             }
//           });
//         }
//       });
//       this.$forceUpdate();
//     },
