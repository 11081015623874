import gql from 'graphql-tag'
export const GET_REUSE =  gql`
query(
    $type: String!
    $page: Int!,
    $perPage: Int!
  ) 
  {
    getReuse(type: $type, page:$page, perPage: $perPage){
      reuseType{
      _id
      name
      enName
      laoName
      }
      totals
    }
    
  }
`