<template>
  <div class="multiselect-container" :class="{ focus: selectFocus }">
    <div class="placeholder" @click="focusSelect()">
      <span
        class="selected-item"
        v-for="(i, index) in selectedItems"
        :key="index"
      >
        {{ i.selectItem }}
      </span>
    </div>
    <div class="multiselect-list" v-if="selectFocus">
      <span>
        <input type="text" class="input" placeholder="Search Job Function" />
      </span>
      <ul>
        <li
          :v-model="selecteds"
          v-for="(i, index) in selectLists"
          :key="index"
          @click="selecting(i)"
        >
          {{ i.selectItem }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    selectFocus: false,
    selectedItems: [{ selectItem: "Office Administration" }],
    selectLists: [
      { selectItem: "IT" },
      { selectItem: "Sales" },
      { selectItem: "Marketing" },
      { selectItem: "Office Administration" },
      { selectItem: "IT" },
      { selectItem: "Sales" },
      { selectItem: "Marketing" },
      { selectItem: "Office Administration" },
    ],
  }),
  methods: {
    selecting(i) {
      if (this.selectedItems.length < 3) {
        this.selectedItems.push(i);
      } else {
        alert("3 Only Okay !?");
      }
    },
    focusSelect() {
      this.selectFocus = !this.selectFocus;
    },
  },
};
</script>

<style lang="scss" scoped>
.multiselect-container {
  cursor: pointer;
  line-height: 1.5;
  border: 1px solid var(--border-color);
  background-color: var(--light-grey-color);
  .placeholder {
    overflow-y: scroll;
    height: 2.5rem;
    color: var(--dark-grey-color);
    display: flex;
    align-items: center;
    position: relative;
    padding-left: calc(0.5em - 1px);
    padding-right: calc(0.5em - 1px);
    padding-top: calc(0.4em - 1px);
    .selected-item {
      white-space: nowrap;
      margin-right: 5px;
      margin-bottom: 5px;
      padding: 3px 28px 3px 10px;
      background-color: var(--primary-color);
      color: #fff;
      position: relative;
      font-size: 16px;
      &::after {
        content: "";
        display: block;
        width: 12px;
        height: 2px;
        background-color: var(--alert-color);
        right: 8px;
        top: 50%;
        transform: rotate(45deg);
        position: absolute;
      }
      &::before {
        content: "";
        display: block;
        width: 12px;
        height: 2px;
        background-color: var(--alert-color);
        right: 8px;
        top: 50%;
        transform: rotate(-45deg);
        position: absolute;
      }
    }
  }
  .multiselect-list {
    position: absolute;
    top: 2.5rem;
    left: 0;
    padding: 20px;
    z-index: 999;
    background-color: #fff;
    box-shadow: 10px 20px 20px 0px rgba(var(--dark-grey-color), 0.2);
    border: 1px solid var(--border-color);
    width: 100%;
    max-height: 340px;
    display: flex;
    flex-direction: column;
    input {
      border: none !important;
    }
    ul {
      margin-top: 10px;
      overflow: auto;
      border-top: 1px solid var(--border-color);
      border-bottom: 1px solid var(--border-color);
      li {
        border-right: 1px solid var(--border-color);
        border-left: 1px solid var(--border-color);
        padding: 8px 10px;
        cursor: pointer;
        transition: all ease-in-out 0.2s;
        &:not(:last-child) {
          border-bottom: 1px solid var(--border-color);
        }
        &:hover {
          background-color: var(--grey-color);
        }
      }
    }
  }
}
</style>
