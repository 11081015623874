<template>
    <div class="select-container">
        <div class="placeholder" @click="showSelectBox">
            <p v-if="!selected.length">Select Job Function</p>
            <p class="selected">{{ getSelection.join(", ") }}</p>
        </div>
        <div class="select-list-container" v-if="isSelect" v-click-outside="() => (isSelect = false)">
            <div class="search">
                <input type="text" class="input" placeholder="Search" v-model="search" />
            </div>
            <div class="select-list">
                <ul>
                    <li v-for="(i, index) in filterKey" :key="index">
                        <p class="main-category" :class="{ checked: i.isCheck }" @click="setStatus(i)">
                            <span></span> {{ i.name }}
                        </p>
                        <div class="sub-category" v-if="i.status">
                            <p v-for="(o, indx) in i.items" :class="{ checked: o.isCheck }" :key="indx"
                                @click="setStatus(o)">
                                <span></span> {{ o.name }}
                            </p>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="buttons">
                <button class="button grey" @click="isSelect = false">Cancel</button>
                <button class="button primary" @click="chooseSelection">Save</button>
            </div>
        </div>
    </div>
</template>
  
<script>
import vClickOutside from "v-click-outside";
import apolloClientCompany from "@/services/apollo-client-company";
import { GET_NEW_JOB_FUNCTION } from "@/graphql/Manage.js";
import { mapper } from "@/services/mapName.js";
export default {
    directives: {
        clickOutside: vClickOutside.directive,
    },
    props: ["value", "updateText"],
    data: () => ({
        info: [],
        selected: [],
        form: [],
        isSelect: false,
        search: "",
        checkUPdate: [],
    }),
    computed: {
        getSelection() {
            if (!this.form.length) return [];
            let isData = [];
            for (let i = 0; i < this.info.length; i++) {
                const obj = this.info[i];
                const isCheck = this.form.find((o) => o._id === obj._id);
                if (isCheck) isData.push(isCheck);
                obj.items.map((item) => {
                    const kuaj = this.form.find((o) => o._id === item._id);
                    if (kuaj) isData.push(kuaj);
                });
            }
            const mapper = isData.map((i) => i.name);
            return mapper;
        },
        filterKey() {
            return this.info.filter((i, index) => {
                let name = i.name.toLowerCase().match(this.search.toLowerCase());
                if (!name) {
                    // Use some() instead of filter() to stop the loop when a match is found
                    name = i.items.some((f) => f.name.toLowerCase().match(this.search.toLowerCase()));
                }
                if (this.search && name) this.info[index].status = true
                return name
            })
        }
    },
    methods: {
        async fetchJobFunction() {
            const res = await apolloClientCompany.query({
                query: GET_NEW_JOB_FUNCTION,
                variables: { search: "" },
            });
            this.info = res.data.getParents
            if (this.info.length) {
                setTimeout(() => {
                    this.updateText.map((i) => {
                        this.checkWhenUpdate(i);
                    });
                }, 500)
            }
        },
        chooseSelection() {
            this.isSelect = false;
            this.form = this.selected;
        },
        showSelectBox() {
            this.isSelect = true;
        },
        setStatus(obj) {
            this.info.map((i, index) => {
                if (i._id === obj._id && !i.isCheck) {
                    this.info[index].isCheck = true;
                    this.info[index].status = true;
                    this.selected.push(mapper(i));
                    i.items.map((it, indx) => {
                        // i.items[indx].isCheck = true;
                        this.selected.push(it);
                    });
                } else if (i._id === obj._id && i.isCheck) {
                    this.info[index].isCheck = false;
                    this.info[index].status = false;
                    this.selected.forEach((item, index) => {
                        if (item._id === i._id) this.selected.splice(index, 1);
                    });
                    i.items.map((it, indx) => {
                        i.items[indx].isCheck = false;
                        this.selected.forEach((item, ix) => {
                            if (item._id === it._id) this.selected.splice(ix, 1);
                        });
                    });
                } else {
                    i.items.map((item, indx) => {
                        if (item._id === obj._id && !item.isCheck) {
                            this.info[index].isCheck = true;
                            i.items[indx].isCheck = true;
                            const isfind = this.selected.find((f) => f._id === i._id);
                            if (isfind) {
                                const isFind = this.selected.find((f) => f._id === obj._id)
                                if (!isFind) this.selected.push(item)
                            } else {
                                this.selected.push(mapper(i), item);
                            }
                            i.items.map((items) => {
                                this.selected.forEach((it, idx) => {
                                    if (it._id === items._id && !it.isCheck) this.selected.splice(idx, 1);
                                })
                            })
                        } else if (item._id === obj._id && item.isCheck) {
                            i.items[indx].isCheck = false;
                            this.selected.forEach((items, idx) => {
                                if (items._id === item._id) this.selected.splice(idx, 1);
                            });
                            // const f = i.items.filter((f) => f.isCheck)
                            // if (!f.length) {
                            //   this.info[index].isCheck = false
                            //   this.selected.forEach((o, idx) => {
                            //     if (o._id === i._id) this.selected.splice(idx, 1)
                            //   })
                            // }
                        }
                    });
                }
            });
            this.$forceUpdate();
        },
        checkWhenUpdate(obj) {
            this.info.map((i, index) => {
                if (i._id === obj._id) {
                    this.info[index].isCheck = true;
                    this.info[index].status = true;
                    this.selected.push(mapper(i));
                } else {
                    i.items.map((item, indx) => {
                        if (item._id === obj._id) {
                            this.info[index].isCheck = true;
                            this.info[index].status = true;
                            i.items[indx].isCheck = true;
                            const isfind = this.selected.find((f) => f._id === i._id);
                            if (isfind) {
                                this.selected.push(item);
                            } else {
                                this.selected.push(mapper(i), item);
                            }
                        }
                    });
                }
            });
            this.chooseSelection();
        },
    },
    created() {
        this.fetchJobFunction();
    },
    watch: {
        form: {
            handler(val) {
                if (val.length) {
                    this.$emit("input", val);
                }
            },
            immediate: true,
            deep: true,
        },
        // updateText: {
        //   handler(val) {
        //     if (val.length) {
        //       setTimeout(() => {
        //         console.log('hey')

        //       }, 1500);
        //     }
        //   },
        //   immediate: true,
        // },
    },
};

</script>
  
<style lang="scss" scoped>
.select-container {
    position: relative;

    .placeholder {
        font-size: 1rem;
        height: 2.5em;
        justify-content: flex-start;
        display: flex;
        align-items: center;
        line-height: 1.5;
        padding-bottom: calc(0.5em - 1px);
        padding-left: calc(0.75em - 1px);
        padding-right: 30px;
        padding-top: calc(0.5em - 1px);
        background-color: var(--light-grey-color);
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        border-radius: var(--radius);
        position: relative;

        &:hover {
            background-color: rgba(#000, 0.15) !important;
        }

        &::after {
            content: "";
            display: block;
            border-left: solid 6px transparent;
            border-right: solid 6px transparent;
            border-top: solid 6px var(--dark-grey-color);
            height: 0;
            width: 0;
            position: absolute;
            right: 10px;
        }

        p {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            margin: 0;
        }
    }

    .select-list-container {
        box-shadow: 0 0 10px 5px rgba(#000, 0.05);
        overflow: hidden;
        position: absolute;
        top: 0;
        z-index: 9;
        width: 100%;
        background-color: #fff;
        border-radius: var(--radius);
        border: 1px solid var(--border-color);
        max-height: 300px;
        display: flex;
        flex-direction: column;

        .search {
            padding: 10px 15px;
            border-bottom: 1px solid var(--border-color);
        }

        .select-list {
            overflow-y: auto;

            ul {
                list-style-type: none;
                margin: 0;
                padding: 20px 15px;

                li {
                    cursor: pointer;
                    margin-bottom: 15px;

                    p {
                        position: relative;
                        display: flex;
                        align-items: center;
                        line-height: 0;
                        margin-bottom: 10px;

                        &.checked {
                            span::before {
                                background: var(--alert-color);
                            }

                            span::after {
                                content: "";
                                position: absolute;
                                left: 5px;
                                top: 9px;
                                background: white;
                                width: 2px;
                                height: 2px;
                                box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white,
                                    4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
                                transform: rotate(45deg);
                            }
                        }

                        span {
                            &::before {
                                content: "";
                                margin-right: 10px;
                                display: inline-block;
                                vertical-align: text-top;
                                width: 20px;
                                height: 20px;
                                background: var(--light-grey-color);
                            }
                        }
                    }

                    .sub-category {
                        margin-left: 30px;
                    }
                }
            }
        }

        .buttons {
            padding: 10px 15px;
            border-top: 1px solid var(--border-color);
            background-color: var(--light-grey-color);
        }
    }
}
</style>
  